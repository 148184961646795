@keyframes fly {
  100% {
    transform: rotate(1turn) translate(50px) rotate(-1turn);
  }
}

@keyframes flyPlus {
  100% {
    transform: rotate(-1turn) translate(100px) rotate(1turn);
  }
}

.wrapper {
  /* width: 100vw; */
  height: 200px;
  background: #fff;
  position: relative;
  overflow: hidden;
  /* border-radius: 40px; */
}

.base {
  position: absolute;
  filter: blur(30px);
  opacity: 0.6;
}

.one {
  border-radius: 100%;
  width: 300px;
  height: 600px;
  background-color: #ae6c17;
  left: -50px;
  top: -300px;
  z-index: 3;
  animation: fly 12s linear infinite;
  transform: rotate(0) translate(80px) rotate(0);
}

.two {
  width: 500px;
  height: 800px;
  background-color: #743b06;
  bottom: -30px;
  left: -80px;
}

.three {
  border-radius: 100%;
  width: 450px;
  height: 450px;
  bottom: -80px;
  right: -100px;
  background-color: #e4a35d;
  animation: flyPlus 8s linear infinite;
  transform: rotate(0) translate(100px) rotate(0);
}

.headerlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.donewrapper {
  width: 100px;
  margin: 1em auto 0;
}

.checkmark {
  stroke: green;
  stroke-dashoffset: 745.74853515625;
  stroke-dasharray: 745.74853515625;
  animation: dash 2.5s ease-out forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 745.74853515625;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.failmark {
  stroke: red;
  stroke-dashoffset: 745.74853515625;
  stroke-dasharray: 745.74853515625;
  animation: dash 2.5s ease-out forwards;
}
